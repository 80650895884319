.header {
    width: 100%;
    grid-area: header;
}

.header__menu {
    font-weight: 700;
}

.header__projectname {
    font-weight: 700;
}