* {
    font-family: Courier, sans-serif;
}

*,
::after,
::before {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

html {
    box-sizing: border-box;
    font-size: 16px;
}

body {
    height: 100vh;
    width: 100vw;
    margin: 0;
    overflow: hidden;
}

input,
button {
    font-family: inherit;
    font-size: inherit;
}

#root {
    height: 100%;
}

.app__wrapper {
    height: 100%;
    display: grid;
    grid-template-areas: ". . content . .";
    grid-template-columns: 1vw 1fr minmax(auto, 1400px) 1fr 1vw;
}

.app__content {
    grid-area: content;
    display: grid;
    grid-template-areas: 
        "."
        "header"
        "."
        "constraints"
        ".";
    grid-template-rows: 1vh minmax(8rem, auto) 1vh 1fr 1vh;
}
