.constraint {
    line-height: 0.9;
    margin-bottom: 1rem;
}

.literal--false {
    color: #ff4b4b;
    font-weight: 200
}

.literal--true {
    color: #004200;
    font-weight: 700;
}