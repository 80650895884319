.constraints {
    width: 100%;
    grid-area: constraints;

    display: flex;
    flex-flow: column nowrap;
}

.constraints__display {
    flex-grow: 2;
}

.constraints__interaction {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: .5rem;
}

.constraints__status {
    background-color: #f3f4f6;
}

.constraints__status--sat {
    color: #004200;
    font-weight: 700;
}

.constraints__status--unsat {
    color: #ff4b4b;
    font-weight: 200
}