.literal {
    padding: .3em;
    display: inline-block;
    cursor: pointer;
}

.literal--false {
    color: #ff4b4b;
    font-weight: 200
}

.literal--true {
    color: #004200;
    font-weight: 700;
}