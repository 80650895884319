.constraint-input {
    display: flex;
    flex-flow: row nowrap;
}

.constraint-input__label {
    display: flex;
    flex-flow: column nowrap;
}

.constraint-input__field {
    margin-right: .5rem;
    border: 1px solid;
}

.constraint-input__field--invalid {
    border-color: red;
    background: lightsalmon;
}